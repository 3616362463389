@import "common/_variables";

article {
    background-color: mix($body-bg, #fff, 0.3);
}
.form.form-inverse {
    background-color: mix($body-bg, #fff, 0.2);
}
.landmarks {
    background-color: mix($body-bg, #fff, 0.7);
}
.em-calendar-wrapper-custom {
    table.em-calendar {
        td.urad:before,
        td.mestska-cast:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
        }
        td.urad:before {
            left: 0;
            top: 0;
            border-left: 6px solid $yellow-color;
            border-bottom: 6px solid white;
        }
        td.mestska-cast:after {
            right: 0;
            bottom: 0;
            border-left: 6px solid white;
            border-bottom: 6px solid $red-color;
        }
    }
}
